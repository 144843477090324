/* CountdownTimer.css */
.countdown-timer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s;
  z-index: 2;

  /* Responsive minimum width based on viewport width */
  min-width: 35vw;
  /* 25% of the viewport width */
  text-align: center;
  white-space: nowrap;
  /* Prevents the text from wrapping */
}