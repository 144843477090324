/* TimerInput.css */

.timer-input-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color-dark); /* Use theme background color */
  color: var(--text-color-dark); /* Use theme text color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-input {
  font-size: 1.2em;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid var(--link-color-dark); /* Use theme accent color */
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background-color-dark); /* Set input background */
  color: var(--text-color-dark); /* Set input text color */
}

.add-timer-button {
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  background-color: var(--link-color-dark); /* Use theme accent color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-timer-button:hover {
  background-color: var(--link-color-dark-hover); /* Use a darker version for hover */
}