/* CurrentTimeDisplay.css */

.time-display {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the element */
  font-size: 3.5em;
  background-color: var(--background-color-dark);
  /* Use theme background color */
  color: var(--text-color-dark);
  /* Use theme text color */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s;
  /* Smooth transition for theme change */
  z-index: 2;
  /* Ensure it's above other page content if necessary */
}