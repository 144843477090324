/* App.css */
/* Define base color scheme using CSS variables */
:root {
  /* Dark theme colors */
  --background-color-dark: #002b36;
  /* solarized dark background */
  --text-color-dark: #93a1a1;
  /* solarized dark text */
  --header-bg-dark: #073642;
  /* solarized darker block elements background */
  --link-color-dark: #268bd2;
  /* solarized dark blue for links and buttons */

  /* Light theme colors */
  --background-color-light: #fdf6e3;
  /* solarized light background */
  --text-color-light: #586e75;
  /* solarized light text */
  --header-bg-light: #eee8d5;
  /* solarized lighter block elements background */
  --link-color-light: #657b83;
  /* solarized gray for links and buttons */
}

/* Apply theme colors based on class */
.App {
  text-align: center;
  background-color: var(--background-color-dark);
  /* Default to dark theme */
  color: var(--text-color-dark);
  transition: background-color 0.3s, color 0.3s;
  /* Smooth transition for theme change */
}

/* Light mode class (overrides dark mode settings) */
.light-mode {
  --background-color-dark: var(--background-color-light);
  --text-color-dark: var(--text-color-light);
  --header-bg-dark: var(--header-bg-light);
  --link-color-dark: var(--link-color-light);
}

/* Header styles */
.App-header {
  background-color: var(--header-bg-dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* Link styles */
.App-link {
  color: var(--link-color-dark);
}

.screen-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backgroundColor: 'rgba(0, 0, 0, 0.5)';
  zIndex: 1;
  /* lower z-index than the TimerInput */
}

.theme-toggle-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5em;
  z-index: 10;
  /* Ensure the button is above other elements */
}