/* AddTimerButton.css */

.add-timer-action-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 4em;
  border-radius: 50%;
  background-color: var(--action-button-bg);
  /* Variable for background color */
  color: var(--action-button-color);
  /* Variable for icon color */
  border: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  /* Variable for box shadow */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/* If you want to style the icon specifically, use this class */
.add-timer-button-icon {
  display: block;
}