/* /home/christopher/REPOS/EVENT-TIMER/client/src/AudioEnabler.css */
.audio-enable-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
    font-size: 3em;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--text-color-dark);
    /* Default color, same as the rest of the app */
}

/* Styling when in light mode */
.audio-enable-button.light {
    color: var(--text-color-light);
}

/* Styling when in dark mode could be omitted if same as default */
.audio-enable-button.dark {
    color: var(--text-color-dark);
}