/* EventTimeDisplay.css */

.event-time-display {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  font-size: 1.5em;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 10px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  z-index: 100;
  /* Ensure it's above other content */
}

.remove-event-button {
  background: none;
  /* No background color to start */
  border: 1px solid transparent;
  /* Transparent border that can be colored on light mode */
  color: var(--icon-color);
  /* Use a custom property for the icon's color */
  cursor: pointer;
  margin-left: 10px;
  /* Add space between the time and button */
  border-radius: 50%;
  /* Make it circular */
  padding: 5px;
  /* Padding inside the button */
  display: inline-flex;
  /* Use flexbox for centering the content */
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

/* On hover, give some background color and a border to stand out */
.remove-event-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  /* Semi-transparent for dark mode */
  border-color: rgba(255, 255, 255, 0.5);
  /* Semi-transparent border */
}

/* On light mode, adjust the button's hover color to match the theme */
.light-mode .remove-event-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* Darken on hover for light mode */
  border-color: rgba(0, 0, 0, 0.3);
  /* Visible border on light mode */
}